import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import * as ReduxThunk from 'redux-thunk';
import * as ReactRouter from 'react-router';
import * as ReactRouterDOM from 'react-router-dom';
import PropTypes from 'prop-types';

// core react libraries
window.React = React;
window.ReactDOM = ReactDOM;
window.ReactDOMServer = ReactDOMServer;
window.Redux = Redux;
window.ReactRedux = ReactRedux;
window.ReduxThunk = ReduxThunk;
window.ReactRouter = ReactRouter;
window.ReactRouterDOM = ReactRouterDOM;
window.PropTypes = PropTypes;
